/* Styles go here */

/*

.hide {
  display: none;
}

.WordDiv:hover + .hide {
  display: block;
  color: red;
}

*/


html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
    background:#FFFFFF;
}

#parent-banner {
   width: 100%;
   margin-top: 0px;
   margin-left: 0px;
   margin-right: 0px;
   margin-bottom: 0px;
}


#p-black-20 {
  font-family: 'Cambria', sans-serif;
  font-size: 20px;
  line-height: 160%;
  font-weight: normal;
  color: #000000;
  margin: 0px;
  padding: 0px;
}

#p-black-25 {
  font-family: 'Cambria', sans-serif;
  font-size: 25px;
  line-height: 160%;
  font-weight: normal;
  color: #000000;
  margin: 0px;
  padding: 0px;
}

#p-black-30 {
  font-family: 'Cambria', sans-serif;
  font-size: 30px;
  line-height: 160%;
  font-weight: normal;
  color: #000000;
  margin: 0px;
  padding: 0px;
}

#p-dark-30 {
  font-family: 'Cambria', sans-serif;
  font-size: 30px;
  line-height: 160%;
  font-weight: normal;
  color: #050533;
  margin: 0px;
  padding: 0px;
}

#p-black-35 {
  font-family: 'Cambria', sans-serif;
  font-size: 35px;
  line-height: 160%;
  font-weight: normal;
  color: #000000;
  margin: 0px;
  padding: 0px;
}

#p-black-40 {
  font-family: 'Cambria', sans-serif;
  font-size: 40px;
  line-height: 160%;
  font-weight: normal;
  color: #000000;
  margin: 0px;
  padding: 0px;
}

#p-black-60 {
  font-family: 'Cambria', sans-serif;
  font-size: 60px;
  line-height: 160%;
  font-weight: normal;
  color: #000000;
  margin: 0px;
  padding: 0px;
}

#p-blue-20 {
  font-family: 'Cambria', sans-serif;
  font-size: 20px;
  line-height: 160%;
  font-weight: normal;
  color: #050533;
  margin: 0px;
  padding: 0px;
}

#p-blue-25 {
  font-family: 'Cambria', sans-serif;
  font-size: 25px;
  line-height: 160%;
  font-weight: normal;
  color: #336699;
  margin: 0px;
  padding: 0px;
}

#p-light-blue-25 {
  font-family: 'Cambria', sans-serif;
  font-size: 25px;
  line-height: 160%;
  font-weight: normal;
  color: #F1F1F1;
  margin: 0px;
  padding: 0px;
}



#p-gold-25 {
  font-family: 'Cambria', sans-serif;
  font-size: 25px;
  line-height: 160%;
  font-weight: normal;
  color: #f1f1f1;
  margin: 0px;
  padding: 0px;
}

#p-blue-30 {
  font-family: 'Cambria', sans-serif;
  font-size: 30px;
  line-height: 160%;
  font-weight: normal;
  color: #050533;
  margin: 0px;
  padding: 0px;
}

#p-blue-35 {
  font-family: 'Cambria', sans-serif;
  font-size: 35px;
  line-height: 160%;
  font-weight: normal;
  color: #050533;
  margin: 0px;
  padding: 0px;
}

#p-blue-40 {
  font-family: 'Cambria', sans-serif;
  font-size: 40px;
  line-height: 160%;
  font-weight: normal;
  color: #050533;
  margin: 0px;
  padding: 0px;
}

#p-blue-45 {
  font-family: 'Cambria', sans-serif;
  font-size: 45px;
  line-height: 160%;
  font-weight: normal;
  color: #050533;
  margin: 0px;
  padding: 0px;
}

#p-blue-50 {
  font-family: 'Cambria', sans-serif;
  font-size: 50px;
  line-height: 160%;
  font-weight: normal;
  color: #050533;
  margin: 0px;
  padding: 0px;
}

#p-blue-60 {
  font-family: 'Cambria', sans-serif;
  font-size: 60px;
  line-height: 160%;
  font-weight: normal;
  color: #050533;
  margin: 0px;
  padding: 0px;
}

#p-white-20 {
  font-family: 'Cambria', sans-serif;
  font-size: 20px;
  line-height: 160%;
  font-weight: normal;
  color: #FFFFFF;
  margin: 0px;
  padding: 0px;
}

#p-white-25 {
  font-family: 'Cambria', sans-serif;
  font-size: 25px;
  line-height: 160%;
  font-weight: normal;
  color: #FFFFFF;
  margin: 0px;
  padding: 0px;
}

#p-white-30 {
  font-family: 'Cambria', sans-serif;
  font-size: 30px;
  line-height: 160%;
  font-weight: normal;
  color: #FFFFFF;
  margin: 0px;
  padding: 0px;
}

#p-white-35 {
  font-family: 'Cambria', sans-serif;
  font-size: 35px;
  line-height: 160%;
  font-weight: normal;
  color: #FFFFFF;
  margin: 0px;
  padding: 0px;
}

#p-white-40 {
  font-family: 'Cambria', sans-serif;
  font-size: 40px;
  line-height: 160%;
  font-weight: normal;
  color: #FFFFFF;
  margin: 0px;
  padding: 0px;
}
 

#p-white-45 {
  font-family: 'Cambria', sans-serif;
  font-size: 45px;
  line-height: 160%;
  font-weight: normal;
  color: #FFFFFF;
  margin: 0px;
  padding: 0px;
}

#p-white-50 {
  font-family: 'Cambria', sans-serif;
  font-size: 50px;
  line-height: 160%;
  font-weight: normal;
  color: #FFFFFF;
  margin: 0px;
  padding: 0px;
}

#p-white-60 {
  font-family: 'Cambria', sans-serif;
  font-size: 60px;
  line-height: 160%;
  font-weight: normal;
  color: #ffffff;
  margin: 0px;
  padding: 0px;
}

#p-red-20 {
  font-family: 'Cambria', sans-serif;
  font-size: 20px;
  line-height: 160%;
  font-weight: normal;
  color: #E34234;
  margin: 0px;
  padding: 0px;
}

#p-red-25 {
  font-family: 'Cambria', sans-serif;
  font-size: 25px;
  line-height: 160%;
  font-weight: normal;
  color: #E34234;
  margin: 0px;
  padding: 0px;
}

#p-red-30 {
  font-family: 'Cambria', sans-serif;
  font-size: 30px;
  line-height: 160%;
  font-weight: normal;
  color: #E34234;
  margin: 0px;
  padding: 0px;
}
  
#p-red-40 {
  font-family: 'Cambria', sans-serif;
  font-size: 40px;
  line-height: 160%;
  font-weight: normal;
  color: #E34234;
  margin: 0px;
  padding: 0px;
}

#p-red-60 {
  font-family: 'Cambria', sans-serif;
  font-size: 60px;
  line-height: 160%;
  font-weight: normal;
  color: #E34234;
  margin: 0px;
  padding: 0px;
}

#p-green-30 {
  font-family: 'Cambria', sans-serif;
  font-size: 30px;
  line-height: 160%;
  font-weight: normal;
  color: green;
  margin: 0px;
  padding: 0px;
}

#p-grey-40 {
  font-family: 'Cambria', sans-serif;
  font-size: 40px;
  line-height: 160%;
  font-weight: normal;
  color: #B0B0B0;
  margin: 0px;
  padding: 0px;
}


#p-blue-1-25 {
  font-family: 'Verdana', sans-serif;
  font-size: 25px;
  line-height: 160%;
  font-weight: normal;
  color: #050533;
  margin: 0px;
  padding: 0px;
}

#p-blue-1-30 {
  font-family: 'Verdana', sans-serif;
  font-size: 30px;
  line-height: 160%;
  font-weight: normal;
  color: #050533;
  margin: 0px;
  padding: 0px;
}

#p-black-1-20 {
  font-family: 'Verdana', sans-serif;
  font-size: 20px;
  line-height: 160%;
  font-weight: normal;
  color: #000000;
  margin: 0px;
  padding: 0px;
}

#p-black-1-25 {
  font-family: 'Verdana', sans-serif;
  font-size: 25px;
  line-height: 160%;
  font-weight: normal;
  color: #000000;
  margin: 0px;
  padding: 0px;
}

#p-white-1-30 {
  font-family: 'Verdana', sans-serif;
  font-size: 30px;
  line-height: 160%;
  font-weight: normal;
  color: #ffffff;
  margin: 0px;
  padding: 0px;
}

#p-black-1-30 {
  font-family: 'Verdana', sans-serif;
  font-size: 30px;
  line-height: 160%;
  font-weight: normal;
  color: #000000;
  margin: 0px;
  padding: 0px;
}



  
#li-list {
  font-family: 'Cambria', sans-serif;
  font-size: 20px;
  line-height: 160%;
  font-weight: 300;
  color: #000000;
  margin: 0px;
  padding: 0px;
}

#banner-div-black {
    background-color: #000000;
    
}

#banner-div-blue {
    background-color: #336699;
    
}

#banner-div-light-grey {
    background-color: #f1f1f1;
    
}
  
#banner-div-white {
    background-color: #FFFFFF;
    
}

.bg-blue{background-color:#6287AB!important}a.bg-light:focus,a.bg-light:hover,button.bg-light:focus,button.bg-light:hover{background-color:#6287AB!important}
.bg-white{background-color:#FFFFFF!important}a.bg-light:focus,a.bg-light:hover,button.bg-light:focus,button.bg-light:hover{background-color:#FFFFFF!important}


.alert-grey { border-color: #0D698B; background: #0D698B; color: #0D698B; }
.alert-cinnabar { border-color: #E34234; background: #E34234; color: #ffffff; }
.alert-dark-blue { border-color: #12184D; background: #12184D; color: #ffffff; opacity: 0.6;}
.alert-ivory { border-color: #F2F1E8; background: #F2F1E8; color: #000000; }
.alert-ivory-border { border-color: #050533; background: #F2F1E8; color: #000000; }
.alert-blue-grotto { border-color: #0D698B; background: #0D698B; color: #000000; opacity: 0.6;}
.alert-light-blue { border-color: #F1F1F1; background: #F1F1F1; color: #000000; }
.alert-light-blue-2 { border-color: #FEFEFE; background: #336699; color: #000000; }


.alert-white { border-color: #FFFFFF; background: #FFFFFF; color: #000000; }
.alert-black { border-color: #050533; background: #050533; color: #ffffff; }
.alert-green { border-color: #81B8BD; background: #81B8BD; color: #000000; }
.alert-yellow { border-color: #FFEA00; background: #FFEA00; color: #000000; opacity: 0.8;}
.alert-red { border-color: #FF0000; background: #FF0000; color: #000000; opacity: 0.6;}
.alert-orange{ border-color: #FF573; background: #FF5733; color: #FF5733; opacity: 0.6; }
.alert-gold { border-color: #959354; background: #959354; color: #000000; }


.alert-1-light-blue { border-color: #20426E; background: #20426E; color: #000000; }
.alert-1-teal { border-color: #54AE93; background: #54AE93; color: #000000; }
.alert-1-pink { border-color: #F0DED3; background: #F0DED3; color: #000000; }
.alert-1-gold { border-color: #C2862D; background: #C2862D; color: #000000; }
.alert-1-dark-blue { border-color: #21184D; background: #21184D; color: #ffffff; }
.alert-1-yellow { border-color: #C2862D; background: #C2862D; color: #000000; }

.alert-2-light-gold { border-color: #DBCA9A; background: #DBCA9A; color: #000000; opacity: 0.6;}
.alert-2-orange { border-color: #C08B51; background: #C08B51; color: #000000; }
.alert-2-brown { border-color: #A7623C; background: #A7623C; color: #ffffff; }
.alert-2-green { border-color: #34544F; background: #34544F; color: #ffffff; }
.alert-2-gold { border-color: #786F5D; background: #786F5D; color: #000000; }
.alert-2-black { border-color: #959354; background: #959354; color: #000000; }


#twitter {color: #0D698B;}
#discord {color: #0D698B;}
#linkedin {color: #0D698B;}


.btn-lg {
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 10px;
}

.btn-xl {
    padding: 10px 20px;
    font-size: 30px;
    border-radius: 10px;
}

.btn-xxl {
    padding: 10px 20px;
    font-size: 40px;
    border-radius: 10px;
}


 /* unvisited link */
a:link {
  text-decoration: none;

}

/* visited link */
a:visited {
  text-decoration: none;
}

/* mouse over link */
a:hover {
  text-decoration: none;
}

/* selected link */
a:active {
  text-decoration: none;
} 






.btn-cinnabar {
  color: #E34234;
  background-color: #E34234;
  border-color: #E34234;
  font-weight: bold;
  letter-spacing: 0.05em;
}

.btn-cinnabar {
  color: #E34234;
  background-color: #E34234;
  border-color: #E34234;
  font-weight: bold;
  border-radius: 0;
}

.btn-cinnabar:hover,
.btn-cinnabar:active,
.btn-cinnabar:focus,
.btn-cinnabar.active {
  background: #E34234;
  color: #FFFFFF;
  border-color: #E34234;
  
}

.parent {
    display: flex;
}


a.explore:link {color: #ffffff;}
a.explore:visited {color: #ffffff;}
a.explore:hover {color: #f1f1f1;}

a.examples:link {color: #ffffff;}
a.examples:visited {color: #ffffff;}
a.examples:hover {color: #f1f1f1;}

a.documentation:link {color: #ffffff;}
a.documentation:visited {color: #ffffff;}
a.documentation:hover {color: #f1f1f1;}

hidden, .KeyPair:hover>div {
  display: none;
}

.KeyPair:hover>hidden {
  display: block;
}

